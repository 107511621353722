document.addEventListener('DOMContentLoaded', function () {
	document.querySelectorAll('.requests-comment').forEach(function (comment) {
		const btn = comment.nextElementSibling;

		if (comment.scrollHeight < 60) {
			btn.style.display = 'none';
		}

		btn.addEventListener('click', function () {
			if (this.classList.contains('active')) {
				this.classList.remove('active');
				comment.style.maxHeight = '60px';
			} else {
				this.classList.add('active');
				comment.style.maxHeight = `${comment.scrollHeight}px`;
			}
		});
	});
});
